<template>
  <div class="idea-access-chooser">
    <div class="py-1 mb-2">
      <el-checkbox
        :value="isCheckedAll"
        :indeterminate="isIndeterminate"
        @change="toggleSelectAll"
      >
        {{ $t('selectAll') }}
      </el-checkbox>
    </div>

    <div
      class="scrollable-wrapper py-1 pr-2"
      :class="{'more': ideas.length > 4}"
    >
      <el-row
        v-for="idea in ideas"
        :key="idea.id"
        class="mb-3 mb-sm-1"
      >
        <el-col :sm="14" class="mb-1 mb-sm-0">
          <el-checkbox
            v-model="idea.enable"
            @change="toggleEnableDisable($event, idea)"
          >
            {{ idea.name }}
          </el-checkbox>
        </el-col>
        <el-col :sm="10">
          <form-switcher
            v-model="idea.role"
            size="xs"
            :options="options"
            :disabled="!idea.enable"
            @change="changeRole($event, idea)"
          />
        </el-col>
      </el-row>
    </div>
    <div v-if="error" class="error-msg-wrapper mt-1">
      <p class="m-0 position-absolute text-danger">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import UserRoleEnum from '@/constants/UserRoleEnum'

export default {
  name: 'IdeaAccessChooser',

  components: { FormSwitcher },

  props: {
    value: {
      type: Array,
      required: true
    },

    error: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      UserRoleEnum,
      options: [
        {
          title: this.$t('userRoles.guest'),
          value: UserRoleEnum.GUEST
        },
        {
          title: this.$t('userRoles.buddy'),
          value: UserRoleEnum.BUDDY
        }
      ]
    }
  },

  computed: {
    ideas: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    countEnabledIdeas () {
      return this.ideas.filter(idea => idea.enable).length
    },

    isCheckedAll () {
      return this.countEnabledIdeas === this.ideas.length
    },

    isIndeterminate () {
      return !!(this.countEnabledIdeas && this.countEnabledIdeas < this.ideas.length)
    }
  },

  methods: {
    changeRole (val, idea) {
      const index = this.findIdeaIndex(idea)
      this.ideas[index].role = val.value
    },

    toggleEnableDisable (val, idea) {
      const index = this.findIdeaIndex(idea)
      this.ideas[index].enable = val
    },

    toggleSelectAll (val) {
      this.ideas.forEach(idea => {
        idea.enable = val
      })
    },

    findIdeaIndex (idea) {
      const found = this.ideas.find(item => item.id === idea.id)

      return this.ideas.indexOf(found)
    }
  }
}
</script>

<style scoped lang="scss">
.idea-access-chooser {
  .scrollable-wrapper {
    overflow: hidden auto;
    max-height: 112px;

    &.more {
      max-height: 115px;

      @include media-breakpoint-up($sm) {
        max-height: 107px;
      }
    }
  }

  .error-msg-wrapper {
    p {
      font-size: 13px;
    }
  }
}
</style>
