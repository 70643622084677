<template>
  <ib-dialog
    :title="$t('dialogs.invite.editAccess')"
    :visible="visible"
    width="750px"
    top="8vh"
    @open="onOpen"
    @close="$emit('close')"
  >
    <div>
      <div v-if="collaborator" class="d-flex flex-column align-items-center mb-5">
        <ib-user-avatar :user="collaborator.user" size="xl" class="mb-2" />
        <h2 class="m-0">
          {{ fullName }}
        </h2>
        <h5 class="m-0">
          {{ collaborator.email }}
        </h5>
      </div>

      <h4 class="m-0 mb-4 text-center">
        {{ $t('dialogs.invite.addRemoveOrEditAccess') }}
      </h4>

      <!-- Idea access chooser -->
      <idea-access-chooser
        v-model="form.ideas"
        :error="error"
      />
      <!-- /Idea access chooser -->
    </div>
    <template #footer>
      <ib-divider class="mb-4" block />
      <ib-dialog-footer
        :loading="loading"
        @submit="onSubmit"
        @cancel="$emit('close')"
      />
    </template>
  </ib-dialog>
</template>

<script>
import IdeaAccessChooser from '@/views/Home/InvitedUsers/Components/IdeaAccessChooser'
import UserRoleEnum from '@/constants/UserRoleEnum'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialogEditCollaboratorAccess',

  components: {
    IdeaAccessChooser
  },

  props: {
    visible: {
      default: false,
      type: Boolean
    },
    collaborator: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      form: {
        ideas: []
      }
    }
  },

  computed: {
    ...mapGetters('ideaCollection', [
      'getIdeas'
    ]),

    hasOneOrMoreIdeasEnabled () {
      return this.form.ideas.reduce((a, item) => {
        return a.concat(item.enable)
      }, []).some(item => {
        return item === true
      })
    },

    error () {
      return !this.hasOneOrMoreIdeasEnabled ? this.$t('pages.users.collaboratorRemovedInfo') : null
    },

    fullName () {
      return this.collaborator.user ? `${this.collaborator.user.firstName} ${this.collaborator.user.lastName}` : null
    },

    collaboratorIdeas () {
      return this.collaborator.ideas.map(item => {
        const { idea, role } = item

        return {
          id: idea.id,
          role
        }
      })
    },

    ideas () {
      return this.getIdeas.map(idea => {
        const existIdea = this.collaboratorIdeas.find(item => item.id === idea.id)
        const { id, name } = idea

        return {
          id,
          name,
          role: existIdea ? existIdea.role : UserRoleEnum.GUEST,
          enable: !!existIdea
        }
      })
    }
  },

  methods: {
    ...mapActions('user/collaborators', ['changeAccess']),

    onOpen () {
      this.form.ideas = this.ideas
    },

    onSubmit () {
      const ideas = this.form.ideas.filter(idea => idea.enable)
      this.loading = true
      this.changeAccess({
        collaboratorId: this.collaborator.id,
        ideas
      }).then(() => {
        this.$emit('close')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
