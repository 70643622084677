<template>
  <div>
    <el-row :gutter="20" class="d-flex flex-column flex-md-row">
      <el-col :md="12" class="d-flex mb-2">
        <action-card
          icon="ib-icon ib-icon-eyes-cartoon"
          :title="$t('pages.users.inviteGuest')"
          :description="$t('pages.users.inviteGuestDescription')"
          @invite="openInviteDialog(UserRoleEnum.GUEST)"
        />
      </el-col>
      <el-col :md="12" class="d-flex mb-2">
        <action-card
          icon="ib-icon ib-icon-group-task"
          :title="$t('pages.users.inviteBuddy')"
          :description="$t('pages.users.inviteBuddyDescription')"
          @invite="openInviteDialog(UserRoleEnum.BUDDY)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ActionCard from '@/views/Home/InvitedUsers/Components/ActionCard'
import UserRoleEnum from '@/constants/UserRoleEnum'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'InviteActions',

  components: {
    ActionCard
  },

  data () {
    return {
      UserRoleEnum
    }
  },

  computed: {
    ...mapGetters('idea', ['getIdeaId']),

    ...mapGetters('user', [
      'isSubscribed',
      'isFreeTrial',
      'isUsersLimitExceeded'
    ])
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState',
      'setDialogUserInviteState'
    ]),

    openInviteDialog (userRole) {
      if (!this.isSubscribed || this.isUsersLimitExceeded) {
        this.setDialogUpgradeState(true)

        return
      }

      this.setDialogUserInviteState({
        role: userRole,
        visible: true
      })
    }
  }
}
</script>
