<template>
  <div id="invited-users">
    <!-- Content -->
    <div class="container-1200">
      <div class="content pt-4">
        <!-- Header -->
        <header class="hidden-sm-and-down">
          <div>
            <h1>
              {{ $t('pages.users.title') }}
            </h1>
            <ib-divider class="divider" block />
          </div>
        </header>
        <!-- /Header -->

        <!-- Content Wrapper -->
        <div class="content-wrapper">
          <invite-actions />
          <current-users />
        </div>
        <!-- /Content Wrapper -->
      </div>
    </div>
    <!-- /Content -->
  </div>
</template>

<script>
import CurrentUsers from '@/views/Home/InvitedUsers/Components/CurrentUsers'
import InviteActions from '@/views/Home/InvitedUsers/Components/InviteActions'

export default {
  name: 'InvitedUsers',

  components: {
    CurrentUsers,
    InviteActions
  }
}
</script>

<style scoped>
.divider {
  margin-bottom: 25px;
  margin-top: 22px;
}

.content {
  padding-bottom: 100px;
}
</style>
