<template>
  <div>
    <!-- Header -->
    <el-row class="d-sm-flex justify-content-between mt-5 mb-3">
      <el-col :sm="12">
        <h2>
          {{ $t('pages.users.activeUsers') }}
        </h2>
      </el-col>
      <el-col :sm="12" class="d-flex justify-content-end align-self-center">
        <h4 class="d-flex m-0">
          <ib-tooltip
            placement="top-start"
            popper-class="large left"
            :content="$t('pages.users.totalCollaboratorsInfo')"
          >
            <i class="cursor-pointer ib-icon-info mr-2 align-self-center" />
          </ib-tooltip>
          <p
            class="m-auto"
            :class="{ upgrade: isUsersLimitExceeded }"
          >
            {{ $t('pages.users.totalCollaborators') }}: {{ countCollaborators }}/{{ usersInPlan }}
          </p>
          <ib-button
            v-if="isUsersLimitExceeded"
            variant="yellow"
            size="sm"
            class="ml-3 pl-4 pr-4 text-uppercase"
            @click="$router.push({ name: 'payment' })"
          >
            {{ $t('upgrade') }}
          </ib-button>
        </h4>
      </el-col>
    </el-row>
    <!-- /Header -->

    <el-row :gutter="20">
      <el-col :md="12" class="mb-3">
        <user-card
          :email="getCurrentUser.email"
          :user="getCurrentUser"
          :role="roleOwner"
          @edit="showDialogEditInvitation"
          @delete="showDialogRemoveUser"
        />
      </el-col>
      <template v-if="collaborators.length">
        <el-col v-for="collaborator in collaborators" :key="collaborator.id" :md="12" class="mb-3">
          <user-card
            :id="collaborator.id"
            :email="collaborator.email"
            :user="collaborator.user"
            :ideas="collaborator.ideas"
            :role="collaborator.role"
            @edit="showDialogEditInvitation"
            @delete="showDialogRemoveUser"
          />
        </el-col>
      </template>
    </el-row>
    <dialog-remove-user
      ref="dialogRemoveInvitation"
      :visible="dialogRemoveInvitationVisible"
      :hide-dialog="hideDialogRemoveUser"
      :confirm="deleteInvitation"
    />

    <dialog-edit-collaborator-access
      :visible="dialogEditInvitationVisible"
      :collaborator="invitationForEdit"
      @close="hideDialogEditInvitation"
    />
  </div>
</template>

<script>
import DialogEditCollaboratorAccess from '@/views/Home/InvitedUsers/Dialogs/DialogEditCollaboratorAccess'
import DialogRemoveUser from '@/views/Home/InvitedUsers/Dialogs/DialogRemoveUser'
import UserCard from './UserCard'
import UserRoleEnum from '@/constants/UserRoleEnum'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CurrentUsers',

  components: {
    DialogRemoveUser,
    DialogEditCollaboratorAccess,
    UserCard
  },

  data () {
    return {
      invitationForEdit: null,
      dialogRemoveInvitationId: false,
      dialogRemoveInvitationVisible: false,
      dialogEditInvitationVisible: false,
      users: []
    }
  },

  computed: {
    ...mapGetters('user/collaborators', [
      'collaborators',
      'countCollaborators'
    ]),
    ...mapGetters('user', [
      'getCurrentUser'
    ]),

    ...mapGetters('user', [
      'usersInPlan',
      'isUsersLimitExceeded'
    ]),

    roleOwner () {
      return UserRoleEnum.OWNER
    }
  },

  mounted () {
    this.getCollaborators()
  },

  methods: {
    ...mapActions('user/collaborators', [
      'getCollaborators',
      'deleteCollaborator'
    ]),

    hideDialogRemoveUser () {
      this.dialogRemoveInvitationVisible = false
      this.dialogRemoveInvitationId = null
    },

    showDialogRemoveUser (id) {
      this.dialogRemoveInvitationId = id
      this.dialogRemoveInvitationVisible = true
    },

    hideDialogEditInvitation () {
      this.dialogEditInvitationVisible = false
      setTimeout(function () {
        this.invitationForEdit = null
      }.bind(this), 500)
    },

    showDialogEditInvitation (id) {
      this.invitationForEdit = this.collaborators.find(invitation => invitation.id === id)
      this.dialogEditInvitationVisible = true
    },

    deleteInvitation () {
      this.$refs.dialogRemoveInvitation.loading = true
      this.deleteCollaborator(this.dialogRemoveInvitationId)
        .then(() => {
          this.hideDialogRemoveUser()
        }).finally(() => {
          this.$refs.dialogRemoveInvitation.loading = false
        })
    }
  }
}
</script>

<style scoped>
.upgrade {
  color: #ffba00;
}

.ib-icon-info {
  opacity: 0.4;
}

.ib-icon-info:hover {
  opacity: 0.8;
}
</style>
