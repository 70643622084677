<template>
  <ib-card
    class="d-flex flex-column px-md-4 px-3 py-3 ib-action-card"
    @click="$emit('invite')"
  >
    <div class="d-flex align-items-center mb-3">
      <i :class="icon" class="h2 mr-2" />
      <h4 class="m-0">
        {{ title }}
      </h4>
    </div>
    <div class="text-description mb-3 text-muted flex-grow-1">
      {{ description }}
    </div>
    <div class="d-flex justify-content-end">
      <ib-button
        outline
        size="sm"
        class="text-uppercase px-lg-4"
        icon="ib-icon ib-icon-add-user-group-man-man"
        @click="$emit('invite')"
      >
        {{ $t('pages.users.invite') }}
      </ib-button>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'ActionCard',

  props: {
    icon: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @include media-breakpoint-up($xl) {
    .text-description {
      width: 75%;
    }
  }
</style>
